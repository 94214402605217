// client/src/translations/translations.js

export const translations = {
    tr: {
      // Home page
      heroTitle: "FinSight",
      heroSubtitle: "Yapay Zeka Destekli Analitikler ile Finansal Verilerinizi Uygulanabilir İçgörülere Dönüştürün",
      getStarted: "Başlayın",
      
      // Features section
      whyChoose: "Neden FinSight?",
      features: {
        analytics: {
          title: "Gelişmiş Analitikler",
          subtitle: "Gerçek zamanlı finansal içgörüler ve analiz"
        },
        security: {
          title: "Kurumsal Güvenlik",
          subtitle: "Verileriniz için banka düzeyinde koruma"
        },
        speed: {
          title: "Işık Hızı",
          subtitle: "Anında yanıtlar ve güncellemeler"
        }
      },
      
      // Analytics section
      realTimeAnalytics: "Gerçek Zamanlı Analitikler",
      
      // About section
      aboutFinSight: "FinSight Hakkında",
      aboutDescription1: "FinSight, yapay zeka destekli finansal analiz asistanınızdır. Kapsamlı finansal verilerle ileri düzey yapay zekayı birleştirerek size uygulanabilir içgörüler ve gerçek zamanlı analitikler sunuyoruz.",
      aboutDescription2: "Platformumuz, karmaşık finansal verileri analiz ederek ve kolay anlaşılır bir formatta sunarak bilgiye dayalı kararlar almanıza yardımcı olur, size zaman kazandırır ve doğruluğu artırır.",
      learnMore: "Daha Fazla Bilgi",
      
      // Contact section
      getInTouch: "İletişime Geçin",
      contactDescription: "FinSight hakkında sorularınız mı var? Yardımcı olmak için buradayız! Bize mesaj gönderin, en kısa sürede size geri döneceğiz.",
      contactInfo: {
        email: {
          title: "E-posta",
          content: "contact@finsight.com"
        },
        phone: {
          title: "Telefon",
          content: "+1 (555) 123-4567"
        },
        address: {
          title: "Adres",
          content: "123 Finans Caddesi, İş Merkezi"
        }
      },
      formLabels: {
        name: "İsim",
        email: "E-posta",
        message: "Mesaj",
        namePlaceholder: "İsminiz",
        emailPlaceholder: "email@ornek.com",
        messagePlaceholder: "Mesajınız",
        send: "Mesaj Gönder"
      },
      
      // Loading
      loading: "Yükleniyor...",
      pleaseWait: "Lütfen bekleyiniz..."
    },
    en: {
      // Home page
      heroTitle: "FinSight",
      heroSubtitle: "Transform Your Financial Data into Actionable Insights with AI-Powered Analytics",
      getStarted: "Get Started",
      
      // Features section
      whyChoose: "Why Choose FinSight?",
      features: {
        analytics: {
          title: "Advanced Analytics",
          subtitle: "Real-time financial insights and analysis"
        },
        security: {
          title: "Enterprise Security",
          subtitle: "Bank-grade protection for your data"
        },
        speed: {
          title: "Lightning Fast",
          subtitle: "Instant responses and updates"
        }
      },
      
      // Analytics section
      realTimeAnalytics: "Real-Time Analytics",
      
      // About section
      aboutFinSight: "About FinSight",
      aboutDescription1: "FinSight is your AI-powered financial analysis companion. We combine cutting-edge artificial intelligence with comprehensive financial data to provide you with actionable insights and real-time analytics.",
      aboutDescription2: "Our platform helps you make informed decisions by analyzing complex financial data and presenting it in an easy-to-understand format, saving you time and improving accuracy.",
      learnMore: "Learn More",
      
      // Contact section
      getInTouch: "Get in Touch",
      contactDescription: "Have questions about FinSight? We're here to help! Send us a message and we'll get back to you as soon as possible.",
      contactInfo: {
        email: {
          title: "Email",
          content: "contact@finsight.com"
        },
        phone: {
          title: "Phone",
          content: "+1 (555) 123-4567"
        },
        address: {
          title: "Address",
          content: "123 Finance Street, Business District"
        }
      },
      formLabels: {
        name: "Name",
        email: "Email",
        message: "Message",
        namePlaceholder: "Your name",
        emailPlaceholder: "your@email.com",
        messagePlaceholder: "Your message",
        send: "Send Message"
      },
      
      // Loading
      loading: "Loading...",
      pleaseWait: "Please wait..."
    }
  };
  
  // Helper function to get translated text
  export const getTranslation = (language, key) => {
    // Split the key by dots to access nested properties
    const keys = key.split('.');
    let translatedText = translations[language];
    
    for (const k of keys) {
      if (translatedText && translatedText[k]) {
        translatedText = translatedText[k];
      } else {
        // Return the key if translation not found
        return key;
      }
    }
    
    return translatedText;
  };