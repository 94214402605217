// HTMLRenderer.js
import React, { useEffect, useRef } from 'react';
import FullScreenButton from './FullScreenButton';

const HTMLRenderer = ({ content }) => {
  const containerRef = useRef(null);
  const plotlyScriptLoaded = useRef(false);

  useEffect(() => {
    if (!containerRef.current || !content) return;

    const loadPlotly = async () => {
      if (!plotlyScriptLoaded.current) {
        const script = document.createElement('script');
        script.src = 'https://cdn.plot.ly/plotly-2.27.0.min.js';
        script.async = true;
        
        await new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
        
        plotlyScriptLoaded.current = true;
      }
    };

    const renderContent = async () => {
      try {
        await loadPlotly();
        containerRef.current.innerHTML = '';

        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '400px';
        wrapper.className = 'plotly-visualization-wrapper';
        wrapper.innerHTML = content;

        containerRef.current.appendChild(wrapper);

        const plotlyDivs = wrapper.getElementsByClassName('plotly-graph-div');
        Array.from(plotlyDivs).forEach(div => {
          div.style.width = '100%';
          div.style.height = '100%';
          
          if (window.Plotly && div._fullData) {
            window.Plotly.relayout(div, {
              'autosize': true,
              'margin': { t: 30, b: 50, l: 50, r: 20 }
            });
          }
        });

        const scripts = wrapper.getElementsByTagName('script');
        Array.from(scripts).forEach(oldScript => {
          const newScript = document.createElement('script');
          Array.from(oldScript.attributes).forEach(attr => {
            newScript.setAttribute(attr.name, attr.value);
          });
          newScript.textContent = oldScript.textContent;
          oldScript.parentNode.replaceChild(newScript, oldScript);
        });

      } catch (error) {
        console.error('Error rendering visualization:', error);
        containerRef.current.innerHTML = '<div class="error-message">Error loading visualization</div>';
      }
    };

    renderContent();

    const handleResize = () => {
      const plotlyDivs = containerRef.current?.getElementsByClassName('plotly-graph-div');
      if (plotlyDivs && window.Plotly) {
        Array.from(plotlyDivs).forEach(div => {
          window.Plotly.relayout(div, {
            'autosize': true
          });
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [content]);

  return (
    <div className="relative">
      <div 
        ref={containerRef}
        className="visualization-container bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 my-4 w-full"
      />
      <FullScreenButton htmlContent={content} />
    </div>
  );
};

export default HTMLRenderer;