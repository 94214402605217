// Message.js
import React from 'react';
import { User, Bot } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import HTMLRenderer from './HTMLRenderer';
import TypingIndicator from './TypingIndicator';

const Message = ({ sender, text, isTyping, htmlContent }) => {
  const CodeBlock = ({ language, value }) => (
    <SyntaxHighlighter language={language} style={tomorrow}>
      {value}
    </SyntaxHighlighter>
  );

  return (
    <div className={`message ${sender} ${isTyping ? 'is-typing' : ''}`}>
      <div className="message-avatar">
        {sender === 'user' ? <User size={24} /> : <Bot size={24} />}
      </div>
      <div className="message-content">
        {text && (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <CodeBlock
                    language={match[1]}
                    value={String(children).replace(/\n$/, '')}
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }
            }}
          >
            {text}
          </ReactMarkdown>
        )}

        {isTyping && <TypingIndicator isTyping={isTyping} />}

        {htmlContent && (
          <div className="visualization-wrapper mt-4">
            <HTMLRenderer content={htmlContent} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;