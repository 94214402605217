import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { 
  ChevronRight, 
  TrendingUp, 
  PieChart, 
  BarChart2, 
  LineChart, 
  Activity, 
  DollarSign, 
  TrendingDown,
  LayoutDashboard
} from 'lucide-react';

const Welcome = ({ onSendMessage, setIsCompanyListOpen }) => {
  const { language } = useLanguage();

  // Define suggested prompts for both languages
  const suggestedPrompts = {
    tr: [
      {
        text: "Hangi firmalarla ilgili bilgi alabilirim?",
        icon: <ChevronRight size={18} className="prompt-icon" />
      },
      {
        text: "Akbank'ın son çeyrek finansal sonuçlarını analiz eder misin?",
        icon: <BarChart2 size={18} className="prompt-icon" />
      },
      {
        text: "Garanti'nin 2019-2023 net karlarını görsellerle analiz edebilir misin?",
        icon: <LineChart size={18} className="prompt-icon" />
      },
      {
        text: "Şişecam'ın cari oranı nedir?",
        icon: <TrendingUp size={18} className="prompt-icon" />
      },
      {
        text: "Tofaş ve Ford Otosan'ın finansal performanslarını karşılaştır",
        icon: <Activity size={18} className="prompt-icon" />
      },
      {
        text: "Koç Holding'in son 5 yıldaki hisse performansını göster",
        icon: <LineChart size={18} className="prompt-icon" />
      },
      {
        text: "BIST100'de en yüksek temettü verimine sahip şirketler hangileri?",
        icon: <PieChart size={18} className="prompt-icon" />
      },
      {
        text: "Petkim'in borç/özsermaye oranını hesapla",
        icon: <TrendingDown size={18} className="prompt-icon" />
      },
      {
        text: "BIST30 bankalarının 2020-2023 karlılık oranlarını gösteren bir dashboard oluştur",
        icon: <LayoutDashboard size={18} className="prompt-icon" />
      }
    ],
    en: [
      {
        text: "Which companies can I get information about?",
        icon: <ChevronRight size={18} className="prompt-icon" />
      },
      {
        text: "Analyze Akbank's financial results for the last quarter",
        icon: <BarChart2 size={18} className="prompt-icon" />
      },
      {
        text: "Can you analyze Garanti's 2019-2023 net profits with visualizations?",
        icon: <LineChart size={18} className="prompt-icon" />
      },
      {
        text: "What is Şişecam's current ratio?",
        icon: <TrendingUp size={18} className="prompt-icon" />
      },
      {
        text: "Compare the financial performance of Tofaş and Ford Otosan",
        icon: <Activity size={18} className="prompt-icon" />
      },
      {
        text: "Show Koç Holding's stock performance over the last 5 years",
        icon: <LineChart size={18} className="prompt-icon" />
      },
      {
        text: "Which BIST100 companies have the highest dividend yield?",
        icon: <PieChart size={18} className="prompt-icon" />
      },
      {
        text: "Calculate Petkim's debt-to-equity ratio",
        icon: <TrendingDown size={18} className="prompt-icon" />
      },
      {
        text: "Create a dashboard showing profitability ratios of BIST30 banks from 2020-2023",
        icon: <LayoutDashboard size={18} className="prompt-icon" />
      }
    ]
  };
  
  const currentPrompts = suggestedPrompts[language];

  const handlePromptClick = (prompt) => {
    onSendMessage(prompt.text);
  };

  // Enhanced welcome note content with more helpful information
  const welcomeNoteTR = (
    <>
      FinSight ile <strong>BIST 100 şirketleri</strong> hakkında finansal analiz, rasyo hesaplama, 
      karşılaştırma ve görselleştirme yapabilirsiniz. Sorularınızı doğal dilde sorabilir, 
      belirli dönemler için <strong>finansal metrikleri</strong> analiz edebilir ve 
      <strong> çoklu şirket karşılaştırmaları</strong> isteyebilirsiniz. Kapsamlı veri 
      görselleştirmeleri ve <strong>interaktif dashboard'lar</strong> oluşturabilirsiniz.
    </>
  );

  const welcomeNoteEN = (
    <>
      With FinSight, you can perform financial analysis, ratio calculations, comparisons, 
      and visualizations for <strong>BIST 100 companies</strong>. You can ask questions in natural language, 
      analyze <strong>financial metrics</strong> for specific periods, and request 
      <strong> multi-company comparisons</strong>. You can also create comprehensive data 
      visualizations and <strong>interactive dashboards</strong>.
    </>
  );

  return (
    <div className="welcome-container">
      <h2>
        {language === 'tr' 
          ? 'Yatırımcı İlişkileri Danışmanı' 
          : 'Investor Relations Assistant'}
      </h2>
      
      <p>
        {language === 'tr' 
          ? 'Aşağıdaki örnek sorulardan birini seçerek veya kendi sorunuzu yazarak başlayabilirsiniz' 
          : 'Get started by selecting one of the example questions below or typing your own'}
      </p>
      
      <div className="suggested-prompts">
        {currentPrompts.map((prompt, index) => (
          <button 
            key={index} 
            onClick={() => handlePromptClick(prompt)}
            className="prompt-button"
          >
            <span className="prompt-icon-wrapper">{prompt.icon}</span>
            <span className="prompt-text">{prompt.text}</span>
          </button>
        ))}
      </div>
      
      <p className="welcome-note">
        {language === 'tr' ? welcomeNoteTR : welcomeNoteEN}
      </p>
    </div>
  );
};

export default Welcome;