import React from 'react';
import { Maximize2 } from 'lucide-react';

const FullScreenButton = ({ htmlContent }) => {
  const handleFullScreen = () => {
    // Create a new window/tab with the visualization
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Visualization Full Screen</title>
            <style>
              body {
                margin: 0;
                padding: 20px;
                background: #f8fafc;
              }
              .container {
                max-width: 1200px;
                margin: 0 auto;
              }
              @media (prefers-color-scheme: dark) {
                body {
                  background: #1a1a2e;
                }
              }
            </style>
          </head>
          <body>
            <div class="container">
              ${htmlContent}
            </div>
          </body>
        </html>
      `);
      newWindow.document.close();
    }
  };

  return (
    <button
      onClick={handleFullScreen}
      className="absolute bottom-2 right-2 bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-lg 
                 shadow-lg transition-all duration-300 flex items-center gap-2 text-sm"
    >
      <Maximize2 size={16} />
      <span>Full Screen</span>
    </button>
  );
};

export default FullScreenButton;