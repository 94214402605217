import React, { useState } from 'react';
import { SendHorizonal, RotateCcw } from 'lucide-react';

function ChatForm({ onSendMessage, onClearChat }) {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() === '') return;
    onSendMessage(message);
    setMessage('');
  };

  return (
    <form className="chat-form" onSubmit={handleSubmit}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here..."
      />
      <button 
        type="submit" 
        disabled={message.trim() === ''} 
        className="send-message-btn"
        title="Send Message"
      >
        <SendHorizonal size={24} />
      </button>
      <button 
        type="button" 
        onClick={onClearChat} 
        className="refresh-chat-btn"
        title="Refresh Chat Session"
      >
        <RotateCcw size={24} />
      </button>
    </form>
  );
}

export default ChatForm;